<template>
<ValidationProvider :rules="rules" :name="name" v-bind="$attrs" v-slot="{invalid, validated}">
  <div class="custom-control custom-checkbox"
       :class="[
       {disabled: disabled},
       {[`custom-checkbox-${type}`]: type},inlineClass]">
    <input :id="cbId"
           class="custom-control-input"
           :class="[{'is-invalid': invalid && validated}, inputClasses]"
           type="checkbox"
           :disabled="disabled"
           v-model="model"/>
    <label :for="cbId" class="custom-control-label">
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</ValidationProvider>
</template>
<script>
export default {
  name: 'validate-checkbox',
  model: {
    prop: 'checked',
  },
  props: {
    checked: {
      type: [Array, Boolean],
      description: 'Whether checkbox is checked',
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled',
    },
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline',
    },
    inputClasses: {
      type: [String, Object, Array],
      description: 'Checkbox input classes',
    },
    type: {
      type: String,
      description: 'Checkbox type (e.g info, danger etc)',
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: '',
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: '',
    },
  },
  data() {
    return {
      cbId: '',
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit('input', check);
      },
    },
    inlineClass() {
      return this.inline ? 'form-check-inline' : '';
    },
  },
  created() {
    this.cbId = Math.random()
        .toString(16)
        .slice(2);
  },
};
</script>
